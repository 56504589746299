.nav-bar {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  background-color: white;
  align-items: center;
  color: black;
  position: fixed; 
  top: 0; 
  left: 0; 
  right: 0; 
  z-index: 1000; 
}

.nav-bar ul {
  list-style: none;
  display: flex;
  gap: 40px;
}

.nav-bar ul li {
  padding-left: 1rem;
  padding-top: 10px;
  font-size: 1rem;
  font-family: 'Trebuchet MS Regular';
}

.nav-bar a {
  text-decoration: none;
  color: black;
  position: relative;
  display: inline-block;
  padding-bottom: 5px; 
  overflow: hidden; 
}

.nav-bar a::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: black; 
  transition: width 0.5s ease-in-out; 
}

.nav-bar a:hover::after {
  width: 100%; 
}

.logo{
  padding-top: 10px;
  padding-left: 1rem;
}

.hamburger {
  display: none; 
  font-size: 20px;
  cursor: pointer;
  padding: 10px 15px; 
  font-family: 'Trebuchet MS Regular';
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
}

.nav-links.open {
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
    cursor: pointer;
  }

  .nav-bar ul {
    flex-direction: column;
    width: 100%;
    display: none;
    position: absolute;
    top: 60px; 
    left: 0;
    background-color: white;
    padding: 0;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }

  .nav-bar ul li {
    text-align: center;
    width: 100%;
  }

  .nav-bar ul.open {
    display: flex;
  }
}
