.container-about{
    width: 100%; 
    max-width: 960px; 
    margin: 0 auto; 
    padding: 40px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); 
    background-color: #fff; 
  }
  
  h1, h2 {
    text-align: center; 
    color: #333; 
    font-family: 'Trebuchet MS Regular';
  }
  
  p, li {
    color: #666; 
    line-height: 1.6; 
    color: black;
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  }
  
  ul {
    padding-left: 20px; 
  }
  
  li {
    margin-bottom: 10px; 
  }


@media (max-width: 768px) {
  .container-about {
      padding: 20px;
  }

  h1, h2 {
      font-size: 18px;
  }

  p, li {
      font-size: 10px; 
  }

  ul {
      padding-left: 15px; 
  }
}

