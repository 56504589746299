
body{
    padding-top: 90px;
}

.home-container {
    max-width: 1704px; 
    margin: 0 auto; 
    padding: 50px 20px; 
    padding-left: 10%; 
}

.headline {
    font-size: 85px; 
    font-weight: bold; 
    margin-bottom: 20px; 
    text-transform: uppercase; 
    text-align: left; 
    font-family: 'Trebuchet MS Regular';
}

.subtext {
    font-size: 20px; 
    color: #555; 
    max-width: 80%; 
    margin: 0 auto 50px; 
    padding-left: 10%; 
    text-align: left; 
}

.services-container {
    display: flex;
    justify-content: space-around; 
    flex-wrap: wrap; 
    margin-top: 50px; 
    margin-right: 100px;
}

.service {
    flex-basis: 20%; 
    display: flex;
    flex-direction: column; 
    align-items: center;
    text-align: center; 
    margin: 20px;
    padding: 10px;
}

.service img {
    width: 200px; 
    height: 200px; 
    border-radius: 50%; 
    margin-bottom: 20px; 
    border: 3px solid #ccc; 
}

.service p {
    font-size: 25px; 
    color: #333; 
}

.image-background {
    background-image: url('../assets/images/house3.jpeg');
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
    height: 500px; 
    width: 92%;   
}

.our-work-container {
    padding: 50px 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-right: 100px;
  }
  
  .our-work-header {
    font-size: 36px; 
    font-weight: bold;
    margin-bottom: 20px; 
  }
  
  .description {
    font-size: 24px; 
    color: #333; 
    margin-bottom: 30px; 
  }
  
  button {
    padding: 10px 20px;
    font-size: 16px;
    color: black;
    background-color: white;
    border: 2px solid black;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  button:hover {
    background-color: grey;
    color: white; 
  }

  @media (max-width: 768px) {
    .home-container, .our-work-container {
        padding: 20px 5%;
    }

    .headline{
        text-align: center;
        font-size: 40px;
    }

    .subtext{
        text-align: center;
        font-size: 10px;
    }
    .description {
        text-align: center;
        font-size: 12px;
    }

    .services-container {
        flex-direction: column;
        justify-content: center;
    }

    .service {
        flex-basis: 100%; 
    }

    .service img {
        width: 120px;
        height: 120px;
    }

    .service p {
        font-size: 16px;
    }

    .our-work-header{
        font-size: 18px;
        text-align: center;
    }

    .description {
        font-size: 12px; 
    }

    button {
        padding: 8px 16px; 
        font-size: 10px; 
    }

    .image-background {
        background-position: top center; 
    }
}